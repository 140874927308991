import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Typography, Grid, Container } from "@mui/material";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { Section } from "../Section";
import { Blockquote } from "../Blockquote";

import { StyledNationwideHandSurgery } from "./NationwideHandSurgery.styled";

const NationwideHandSurgeryContent = ({ children }) => {
  return (
    <StyledNationwideHandSurgery>
      <Section>
        <Container maxWidth="lg">
          <Box p={4}>
            <Typography variant="h4" color="secondary" align="center" mb={2}>
              Hand & Wrist
            </Typography>
            <Typography variant="h5" align="center" mb={2}>
              <i>
                "Complete Hand Surgery - Care from Reconstruction to
                Rejuvenation"
              </i>
            </Typography>
            <Typography paragraph>
              The hand and wrist are very complex; therefore, seeing a hand
              specialist can yield the best solution for conditions of the hand,
              wrist and arm. The Joyner Hand provides a number of surgical and
              non-surgical procedures for a variety of hand and arm conditions.
            </Typography>
            <Typography paragraph>
              A few of the services provided by TJH are as follows:
            </Typography>
            <Grid container>
              <Grid item xs={12} md={4}>
                <ul>
                  <li>Carpal tunnel</li>
                  <li>Arthritis</li>
                  <li>Wrist pain</li>
                  <li>Trigger finger</li>
                  <li>Ganglion cysts</li>
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <ul>
                  <li>Hand tumors</li>
                  <li>Scar removal and revision</li>
                  <li>Worker's compensation</li>
                  <li>Artificial joint replacements</li>
                  <li>Dupuytren's contracture</li>
                </ul>
              </Grid>

              <Grid item xs={12} md={4}>
                <ul>
                  <li>Nerve repair</li>
                  <li>Tendon injuries</li>
                  <li>Sports injuries</li>
                  <li>Fingertip injuries</li>
                  <li>Infections</li>
                </ul>
              </Grid>
            </Grid>
            <Typography paragraph>
              See the patient education section for further conditions and
              procedures performed by The Joyner Hand.
            </Typography>
          </Box>
        </Container>
      </Section>
      <Section secondary>
        <Container maxWidth="sm">
          <Box py={4}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" mb={4}>
                  <StaticImage
                    src="../../images/spa.jpg"
                    alt="young old hand"
                  />
                </Box>
                <Box my={4} display="flex" justifyContent="flex-start">
                  <Blockquote light>
                    "The hands are a window to one's heart and soul." - MSJ
                  </Blockquote>
                </Box>
                <Box my={4} display="flex" justifyContent="flex-start">
                  <Blockquote light>
                    By a persons fingernails... by the callosities of his
                    forefinger and thumb... by each of these things an
                    individual's calling is revealed." - Sherlock Holmes
                  </Blockquote>
                </Box>
                <Box my={4} display="flex" justifyContent="flex-start">
                  <Blockquote light>
                    "The hand is the extension of the brain." - Anaxagoras
                  </Blockquote>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Section>
    </StyledNationwideHandSurgery>
  );
};

const NationwideHandSurgery = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "hero-hand-surgery-national.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const pluginImage = getImage(placeholderImage);

  return (
    <>
      <BgImage image={pluginImage} style={{ minHeight: 375 }}>
        <Box
          sx={{
            height: 375,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `middle`,
          }}
        >
          <Box>
            <Typography variant="h3" align="center" color="secondary">
              <strong> Hand Surgery National</strong>
            </Typography>
            {/* <Typography variant="h4" align="center" color="secondary">
              Your hands deserve it
            </Typography> */}
          </Box>
        </Box>
      </BgImage>
      <NationwideHandSurgeryContent />
    </>
  );
};

export default NationwideHandSurgery;
