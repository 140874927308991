import * as React from "react";
import { Layout } from "../components/Layout";
import { NationwideHandSurgery } from "../components/NationwideHandSurgery";

export default function NationwideHandSurgeryPage() {
  return (
    <Layout>
      <NationwideHandSurgery />
    </Layout>
  );
}
